@mixin new-checkbox
    vertical-align: middle
    width: 20px
    height: 20px
    margin: 6px 4px
    border: 1.5px solid #ccc
    border-radius: 3px
    appearance: none
    cursor: pointer
    transition: background-color 0.2s ease, border-color 0.2s ease

    &:hover
        background-color: #f2f9fa
        border-color: #00a4af

    &:checked
        display: inline-block
        width: 20px
        height: 20px
        border: 1px solid #008C95
        border-radius: 10%
        background-color: #008C95
        position: relative

    &:checked::before
        content: ""
        position: absolute
        margin-top: 4px
        margin-left: 3px
        width: 12px
        height: 6px
        border: 2.5px solid #fff
        border-left: none
        border-bottom: none
        transform: rotate(135deg)

    &:disabled:checked 
        background-color: rgb(224, 224, 224)
