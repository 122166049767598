@mixin user-content-children
  > * + *
    margin-top: 0.8rem
    margin-bottom: 0.8rem

  > br
    margin-top: 0

  > .op-uc-h1 + *,
  > .op-uc-h2 + *,
  > .op-uc-h3 + *,
  > .op-uc-h4 + *,
  > .op-uc-h5 + *
    margin-top: 0

  .op-uc-figure
    &_align-start,
    &_align-end
      margin-bottom: 1rem

  h3
    &.category
      font-family: "Bebas Neue"
      font-size: 20px
      font-weight: bold
      color: #555
      letter-spacing: 10px
      margin: 0
      padding: 0
    &.document-subtitle
      font-family: "Bebas Neue"
      font-size: 20px
      color: #555
      margin: 0 0 1em
      font-weight: normal
      padding: 0
  h2
    &.document-title
      font-family: "Bebas Neue"
      font-size: 50px
      font-weight: bold
      margin: 0
      padding: 0
      border: 0
  p
    &.info-box
      --background-size: 30px
      --background-color: #e91e63
      padding: 1.2em 2em
      border: 1px solid var(--background-color)
      background: #fff
      border-radius: 10px
      margin: 1.5em 2em
      box-shadow: 5px 5px 0 #ffe6ef
  blockquote
    &.side-quote
      font-family: "Bebas Neue"
      font-style: normal
      float: right
      width: 35%
      position: relative
      border: 0
      overflow: visible
      z-index: 1
      margin-left: 1em
      &::before
        content: "“"
        position: absolute
        top: -37px
        left: -10px
        display: block
        font-size: 200px
        color: #e7e7e7
        z-index: -1
        line-height: 1
      p
        font-size: 2em
        line-height: 1
        &:last-child
          &:not(:first-child)
            font-size: 1.3em
            text-align: right
            color: #555
  span
    &.marker
      background: yellow
    &.spoiler
      background: #000
      color: #000
      &:hover
        background: #000
        color: #fff    